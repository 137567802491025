import React from 'react';
import styles from './PFSWhatWeDoTaxes.module.css';
import globalStyles from '../../styles/global.module.css';
import ContentContainer from '../ContentContainer/ContentContainer';
import PFSContactCta from '../PFSContactCta/PFSContactCta';
import PFSServiceHeader from '../PFSServiceHeader/PFSServiceHeader';
import PFSServicePoints from '../PFSServicePoints/PFSServicePoints';
import PFSWhyChoosePFS from '../PFSWhyChoosePFS/PFSWhyChoosePFS';
import PFSWaveContainer from '../PFSWaveContainer/PFSWaveContainer';
import PFSMeetTheTeam from '../PFSMeetTheTeam/PFSMeetTheTeam';

import PFSServiceCard from '../PFSServiceCard/PFSServiceCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCalendarDays,
  faFolderOpen,
  faMoneyBillTrendUp,
} from '@fortawesome/free-solid-svg-icons';

import taxFormImage from './taxes.jpg'
import { useBreadcrumb } from '../../hooks/useBreadcrumb';
import { WhatWeDoBreadcrumb } from '../PFSWhatWeDoIndex/PFSWhatWeDoIndex';

export const WhatWeDoTaxesBreadcrumb = [
  WhatWeDoBreadcrumb,
  {
    label: 'Taxes',
    url: '/what-we-do/taxes',
  },
];
const PFSWhatWeDoTaxes = () => {
  useBreadcrumb(WhatWeDoTaxesBreadcrumb)
  const currYear = new Date().getFullYear();

  return (
    <div className={styles.PFSWhatWeDoTaxes} data-testid='PFSWhatWeDoTaxes'>
      <ContentContainer>
        <PFSServiceHeader subtitle='Tax' title='Taxes for the Modern Law Firm.' />
        <div className={globalStyles.IntroContainer}>
          <img alt='Tax Forms' src={taxFormImage} className={globalStyles.IntroImage} />
          <div className={globalStyles.IntroText}>
            <p>At PFS Global, we specialize in Law Firm tax services for both individuals and businesses. Our team of tax professionals provides comprehensive tax solutions such as income tax preparation and tax planning to ensure compliance with tax regulations.</p>
            <p>Our team of experienced professionals stays up-to-date on the latest tax regulations and industry trends, providing accurate and timely advice to help you achieve your financial goals. Contact us today to learn more about our tax services.</p>
          </div>
        </div>
        <PFSServicePoints 
          title='Income Tax Preparation' 
          points={[
            "Business Tax Preparation",
            "Individual Tax Preparation",
            "Tax Planning",
          ]}
        />
      </ContentContainer>
      <PFSWhyChoosePFS />
      <PFSMeetTheTeam department='Tax' />
      <PFSWaveContainer color='#2D75A9'>
        <ContentContainer>
          <center>
            <p id={'#tools'} className={styles.TitleText}>
              Tax Tools
            </p>
            <p id={styles.SubtitleText}>
              Here is a list of tools and tips designed to help make your tax
              preparation a little easier
              <br />
              Get a jump start on your taxes with our {currYear} Tax Checklist and our{' '}
              {currYear} Tax Organizer
            </p>
          </center>
          <div id={styles.ServiceCardsContainer}>
            <div className={styles.ServiceCardContainer}>
              <PFSServiceCard
                icon={<FontAwesomeIcon size='4x' icon={faMoneyBillTrendUp} />}
                title={'Rates'}
                subtitle={'Tax'}
                url={'/what-we-do/taxes/tax-rate-tool'}
              />
            </div>
            <div className={styles.ServiceCardContainer}>
              <PFSServiceCard
                icon={<FontAwesomeIcon size='4x' icon={faCalendarDays} />}
                title={'Dates'}
                subtitle={'Due'}
                url={'/what-we-do/taxes/due-dates-tool'}
              />
            </div>
            <div className={styles.ServiceCardContainer}>
              <PFSServiceCard
                icon={<FontAwesomeIcon size='4x' icon={faFolderOpen} />}
                title={'Guides'}
                subtitle={'Record Retention'}
                url={'/what-we-do/taxes/tax-guides-tool'}
              />
            </div>
          </div>
        </ContentContainer>
      </PFSWaveContainer>
      <br />
      <br />
      <br />
      <br />
      <br />
      <PFSContactCta ctaText='Call us today!' />
    </div>
  );
};

PFSWhatWeDoTaxes.propTypes = {};

PFSWhatWeDoTaxes.defaultProps = {};

export default PFSWhatWeDoTaxes;
