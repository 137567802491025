import React from 'react';
import styles from './PFSHomeBanner.module.css';
import ContentContainer from '../ContentContainer/ContentContainer';

import pfsIcon from '../PFSIcon/pfs_icon.svg';
import stockPro from './stock_professional.png';
import { Link } from 'react-router-dom';

const PFSHomeBanner = () => (
  <ContentContainer>
    <div className={styles.PFSHomeBanner} data-testid="PFSHomeBanner">
      <div className={styles.CTAText}>
        <h1 id={styles.HeaderText}>
          Solving for
          <br></br>
          <span id={styles.BoldHeader}>the Modern Law Firm.</span>
        </h1>
        <p id={styles.BannerText}>
          PFS Global is a trusted financial management partner, providing innovative solutions to law firms across the United States.
        </p>
        <Link to={'/what-we-do'} id={styles.LearnMoreBtn}>
          Learn More.
        </Link>
      </div>
      <div className={styles.BannerGraphic}>
        <img alt='PFS Logo Faded' src={pfsIcon} id={styles.BannerFadedLogo} />
        <img alt='Professional Man' src={stockPro} id={styles.BannerStockProfessional} />
      </div>
    </div>
  </ContentContainer>
);

PFSHomeBanner.propTypes = {};

PFSHomeBanner.defaultProps = {};

export default PFSHomeBanner;
