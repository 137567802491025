import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styles from './PFSWhoWeAre.module.css';
import globalStyles from '../../styles/global.module.css';
import PFSWhoWeAreContent from '../PFSWhoWeAreContent/PFSWhoWeAreContent';
import { getStaffData } from '../../lib/GetStaffData';
import PFSContactCta from '../PFSContactCta/PFSContactCta';
import { useRollbar } from '@rollbar/react';
import PFSWhoWeAreCollage from '../PFSWhoWeAreCollage/PFSWhoWeAreCollage';
import ContentContainer from '../ContentContainer/ContentContainer';

import WhoWeAreContext from '../../contexts/WhoWeAreContext';
import { useBreadcrumb } from '../../hooks/useBreadcrumb';

const PFSWhoWeAre = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [staffData, setStaffData] = useState([]);
  const [currSection, setCurrSection] = useState('');
  const rollbar = useRollbar();
  const { pathname } = useLocation()

  useEffect(() => {
    getStaffData()
      .then((response) => {
        setStaffData(response);
      })
      .catch((e) => {
        rollbar.error(e);
        setStaffData([]); // TODO: Replace this with error image
      })
      .finally(() => {
        setLoading(false);
      });
  }, [rollbar]);

  const staffNameToId = useCallback(
    (name) => `#${name?.split(' ').join('')}`,
    []
  );

  const navigateToStaff = useCallback(
    (staffMem) => {
      setCurrSection(staffMem.department);
      const hash = staffNameToId(staffMem.name);
      navigate(hash);
    },
    [staffNameToId, navigate]
  );

  useBreadcrumb([{
    label: "Who We Are",
    url: pathname
  }])

  const introClasses = [globalStyles.IntroText, styles.IntroText];

  return (
    <div className={styles.PFSWhoWeAre} data-testid='PFSWhoWeAre'>
      <center>
        <p className={globalStyles.TitleText}>Who We Are</p>
      </center>
      <ContentContainer>
        <center className={introClasses.join(' ')}>
          <p>
            Meet the passionate and skilled team behind PFS Global. Our team members come
            from diverse backgrounds and bring a wide range of expertise to our bookkeeping, tax,
            payroll, HR, and advisory services.
          </p>
          <br />
        </center>
      </ContentContainer>
      <WhoWeAreContext.Provider
        value={{
          loading,
          staffData,
          currSection,
          setCurrSection,
          staffNameToId,
          onCollageStaffClick: navigateToStaff,
        }}
      >
        <PFSWhoWeAreCollage />
        <PFSWhoWeAreContent />
      </WhoWeAreContext.Provider>
      <PFSContactCta
        ctaText='Free Consultation!'
        metaText='Call us today to receive your'
      />
    </div>
  );
};

PFSWhoWeAre.propTypes = {};

PFSWhoWeAre.defaultProps = {};

export default PFSWhoWeAre;
