import React from 'react';
import ContentContainer from '../ContentContainer/ContentContainer';
import PFSContactCta from '../PFSContactCta/PFSContactCta';
import PFSServiceHeader from '../PFSServiceHeader/PFSServiceHeader';
import PFSServicePoints from '../PFSServicePoints/PFSServicePoints';
import PFSWhyChoosePFS from '../PFSWhyChoosePFS/PFSWhyChoosePFS';
import styles from './PFSWhatWeDoBookkeeping.module.css';
import globalStyles from '../../styles/global.module.css';
import PFSMeetTheTeam from '../PFSMeetTheTeam/PFSMeetTheTeam';

import calculatorImage from './calculator.jpg'
import { useBreadcrumb } from '../../hooks/useBreadcrumb';
import { WhatWeDoBreadcrumb } from '../PFSWhatWeDoIndex/PFSWhatWeDoIndex';
import { useLocation } from 'react-router-dom';

const PFSWhatWeDoBookkeeping = () => {
  const { pathname } = useLocation();

  useBreadcrumb([ WhatWeDoBreadcrumb, {
    label: "Bookkeeping",
    url: pathname
  } ]);

  return (
    <div
      className={styles.PFSWhatWeDoBookkeeping}
      data-testid='PFSWhatWeDoBookkeeping'
    >
      <ContentContainer>
        <PFSServiceHeader title='Bookkeeping for the Modern Law Firm.' subtitle='Bookkeeping' />
        <div className={globalStyles.IntroContainer}>
          <img alt='A calculator' src={calculatorImage} className={globalStyles.IntroImage} />
          <div className={globalStyles.IntroText}>
            <p>At PFS Global, we understand that keeping your law firm's finances in order can be a daunting task. That's why we offer expert bookkeeping services tailored to your specific needs. When properly using your Legal Software for bookkeeping, we can provide you with the tools and insights necessary to make informed decisions for your firm.</p>
            <p>With PFS Global, you can rest assured that your finances are in capable hands, allowing you to focus on what you do best: running your Law Firm. Contact us today to learn how we can help you achieve your financial goals.</p>
          </div>
        </div>
        <PFSServicePoints
          title='Income Tax Cash Basis'
          points={[
            {
              label: 'IOLTA Trust Accounting',
              subpoints: [
                'Three-Way Reconciliation: General Ledger, Client Ledger, Bank Account Reconciliation',
                'In Compliance with the ABA and State Bars',
              ],
            },
            'Data Entry of Operating Bank, Credit Card, and Loan Accounts',
            'Reconciliation of Operating Bank, Credit Card, and Loan Accounts',
            'Month-End Closing of Books',
            'Monthly Management Reports',
            '1099/1042 Processing',
          ]}
        />
        <PFSServicePoints
          title='Income Tax Accrual Basis (The Above Plus)'
          points={[
            'Accounts Payables',
            'Accounts Receivables',
            'Client Costs Receivable',
            'Prepaid Expenses',
            'Accrued Expenses',
            'Deferred Expenses',
          ]}
        />
        <PFSServicePoints
          title='Generally Accepted Accounting Principles Basis (The Above Plus)'
          points={[
            'Asset Management',
            'Liability Management',
            'Revenue Recognition',
            'Business Combinations',
            'Leasing Standards',
            'Capitalization Table Management',
          ]}
        />
      </ContentContainer>
      <PFSWhyChoosePFS />
      <PFSMeetTheTeam department='Bookkeeping' />
      <br />
      <br />
      <br />
      <br />
      <br />
      <PFSContactCta ctaText='Call us today!' />
    </div>
  )
};

PFSWhatWeDoBookkeeping.propTypes = {};

PFSWhatWeDoBookkeeping.defaultProps = {};

export default PFSWhatWeDoBookkeeping;
